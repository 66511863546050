@charset "UTF-8";

$primary-color: #CEEBFA !default;
$primary-color-dark: #a4b9c4 !default;

$default-color: #CEEBFA !default;
$default-color-dark: #a4b9c4 !default;

$secondary-color: #CEEBFA !default;
$secondary-color-dark: #a4b9c4 !default;

$elegant-color: #a5a5a8 !default;
$elegant-color-light: #e5e5e8 !default;
$elegant-color-dark: #1b1c21 !default;

$warning-color: #ff1e00 !default;
$warning-color-dark: #ff1e00 !default;

$danger-color: #ff1e00 !default;
$danger-color-dark: #ff1e00 !default;

$yellow: #dbaf58 !default;
$amber: #dbaf58 !default;
$gg-gold: #afbfc9 !default;
$gg-cold: #727e98 !default;
$gg-blue: $gg-cold;

