@charset "UTF-8";

@import "glyphicons.scss";
@import "fonts.scss";
@import "colors.scss";

@import "~font-awesome/scss/font-awesome.scss";
@import "~bootstrap/scss/bootstrap.scss";

$image-path: "../../../../node_modules/mdbootstrap/img/" !default;
@import "~mdbootstrap/scss/mdb.scss";
@import "~nouislider/distribute/nouislider.css";
@import "~select2/src/scss/core.scss";
@import "~eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css";
@import "~lightbox2/src/css/lightbox.css";


// CSS overrides where SASS overrides are not possible
.bg-light {
  background-color: #f5f5f5 !important;
}

.bg-radius {
  background-color: #F8EFDE !important;
}

.bg-elegant {
  background-color: $elegant-color !important;
}

.bg-elegant-light {
  background-color: $elegant-color-light !important;
}

.text-primary {
  color: $primary-color !important;
}

.text-dark {
  color: #515154 !important;
}

.text-danger {
  color: $danger-color !important;
}



html {
  height: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold !important;
}

a {
  color: $gg-blue;
}

.btn-flat {
  box-shadow: none;
}

#navbarSupportedContent2 {
  margin-top: 10px;
}

@media screen and (max-width: 600px) {
  #navbarSupportedContent2 {
    margin-top: 8px;
  }
}

@media screen and (max-width: 500px) {
  #logoimage {
    width: 155px;
  }
}

@media screen and (max-width: 400px) {
  #logoimage {
    width: 108px;
  }
}

.nav-item {
  text-transform: uppercase;
}

.navbar.navbar-dark .navbar-nav .nav-item.active > .nav-link {
  font-weight: bold;
  color: $elegant-color-dark;
  border-bottom: 1px solid $elegant-color-dark;
  background-color: inherit;
}

.navbar-brand {
  line-height: 0;
}

.nav-link {
  color: black;
  line-height: normal;
}

.dropdown-item:hover {
  background-color: $primary-color-dark !important;
}

.dropdown-item > a.nav-link {
  color: #000 !important;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: $primary-color !important;
  color: $white;
  font-weight: bold;
}

.dropdown-menu.dropdown-menu-right.overflow-auto.show {
  min-width: 20rem;
}

// https://github.com/mdbootstrap/bootstrap-material-design/commit/1f7a342fcdfad1a9898306f9629564aaa4987f96#diff-cd6d18a25aa1c9890649a4200479808aR6805-R6809
// introduces a change that results in cut off nav dropdowns on some mobile phones, this override prevents that.
.navbar.fixed-top {
  overflow-x: visible;
  overflow-y: visible;
}

.page-background {
  background-size: cover;
  height: 100%;
  overflow: auto;
  position: relative;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;

  display: block;
}

.page-background-overlay-box {
  max-width: 1280px;
  margin-top: -60px;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
}

#outermost-main-container {
  max-width: 100%;
}

@media screen and (max-width: 575px) {
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 0;
    padding-left: 0;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
  }

  #outermost-main-container {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .page-background-overlay-box {
    padding-top: 5px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media screen and (max-width: 500px) {
  .fixed-top-secondary {
    margin-top: 55px;
  }
}

@media screen and (min-width: 501px) {
  .fixed-top-secondary {
    margin-top: 65px;
  }
}

#seals-sidebox {
  position: fixed;
  right: 0;
  top: 161px;
  padding: 12px 0 0 8px;
  background-color: rgba(0, 0, 0, 0.5);
}

h1.homepage-headline {
  font-size: 3.3em;
  font-weight: bold;
  color: #fefefe;
}

h2.homepage-headline {
  font-size: 2em;
  font-weight: bold;
  color: $gg-gold;
}


@media only screen and (max-device-width : 480px) {
  .bordered-homepage-textbox {
    border: 3px solid $primary-color;
  }
}
@media only screen and (min-device-width : 481px) {
  .bordered-homepage-textbox {
    border: 9px solid $primary-color;
  }
}

.invalid-feedback {
  font-size: 100%;
  padding-left: 16px;
  margin-bottom: 4px;
  display: inline !important;
}

.invalid-feedback > span {
  display: inline !important;
}

.form-control-label {
  font-weight: bold;
}

.form-control-label > p {
  font-weight: normal;
}

.form-control:focus {
  border-color: $primary-color-dark;
}

.form-inline-zipcode {
  color: $primary-color-dark;
  border-bottom-color: $primary-color-dark;
}

.additional-file-link {
  display: inline-block;
  margin-bottom: 9px !important;
  padding: 6px;
  border: 1px dotted lightgrey;
  background-color: #eee;
}

legend {
  font-size: 1rem;
  font-weight: bold;
}

.datetimepicker-input {
  width: 140px;
}

.datetimepicker-input > .input-group-addon {
  margin-top: 17px;
  margin-left: 10px;
}

.datetimepicker-input > input {
  text-align: right;
  max-width: 80px;
}

.bootstrap-datetimepicker-widget {
  left: 0px !important; /* Avoid clipping the left half of the widget on small screens */
}

/* Fix to make select2 fully responsive */
.select2 {
  width:100%!important;
}

.select2-selection__choice {
  background: $primary-color-dark !important;
  color: #fff !important;
}

.select2-selection__choice__remove {
  color: #fff !important;
}

.select2-results__option[aria-selected="true"] {
  display: none;
}

.file-collection-label, fieldset legend {
  display: none;
}

.checkmark-in-contentpage-list {
  color: #ff1e00;
}

.enhanced-checkbox {
  width: 30px;
  height: 30px;
  background: #ddd;
  margin: 0px 0px;
  margin-left: auto;
  margin-right: auto;

  border-radius: 5px;
  position: relative;
}

.enhanced-checkbox input[type="checkbox"] {
  visibility: hidden;
}

.enhanced-checkbox label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  opacity: 0;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 5px;

  transition: all .2s ease;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  background: #fff;

  text-align: center;
  font-weight: bold;
  color: white;
  font-size: 1.4em;
  margin-top: 0;
  line-height: 1.5em;
}

@media screen and (max-width: 991px) {
  .enhanced-checkbox {
    width: 20px;
    height: 20px;
    border-radius: 3px;
  }
  .enhanced-checkbox label {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    font-size: 1em;
  }
}

.enhanced-checkbox input[type=checkbox]:checked + label {
  opacity: 1;
  background: $primary-color;
  border-radius: 5px;
}

.slider {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.slider .noUi-handle {
  background: $primary-color;
  box-shadow: none;
}

.form-inline-zipcode {
  display: inline-block;
  max-width: 77px;
  text-align: center;
  margin-left: 4px;
  padding-bottom: 0px;
  font-weight: bold;
}

#message-check-card {
  padding: 0 !important;
  position: fixed;
  bottom: 0;
  max-width: 100% !important;
  width: 100% !important;
  margin: 0;
  z-index: 439834;
}

.shortprofile-in-message {
}

.conversation-message-subject-and-body a {
  color: #000;
}

.conversation-message-body-text a.conversation-message-body-link {
  text-decoration: underline;
  color: #0d47a1;
}

.conversation-message-subject-and-body blockquote {
  font-size: 1rem;
  padding: 0;
  border-left: inherit;
}

.recurrent-job-header, .profile-header, .wanted-job-header {
  background-color: $gg-cold;
  color: $gg-gold;
}

#scrolled-page-box {
  display: none;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}

.multiple-message-button, .result-cta-button {
  background-color: $gg-cold !important;
  color: $gg-gold !important;
  text-decoration: underline;
}

.multiple-message-button {
  color: $white !important;
}

.multiple-message-batch-button {
  width: 97%;
}

.favorization-link {
}

.profile-block i, .profile-block span {
}

.experience {
}


@media (max-width: 576px) {
  .sf-minitoolbar {
    display: none !important;
  }
}

.content-body {

}

.content-body-benefits-jobseeker {
  background-image: url("../../public/assets/images/background-benefits-jobseeker.jpg");
  background-size: cover;
}

.smallprint {
  font-size: 75%;
}

.smallprint h1, .smallprint h2, .smallprint h3, .smallprint h4, .smallprint h5, .smallprint h6 {
  margin-top: 40px;
  font-size-adjust: 0.4;
}

footer {
  background: $elegant-color-dark;
  color: $elegant-color;
  /*text-transform: uppercase; */
  font-weight: 300;
  font-size: .9rem;
  /* min-height: 700px; */
}

footer .upper {
  text-transform: uppercase;
}

footer .copyright {
  text-transform: none;
}

footer .table td {
  border: none;
}

footer a {
  color: $elegant-color !important;
}

.checkmark-list {
  font-weight: 300;
}

.checkmark-list.checkmark-list-large table td {
  font-size: 110%;
  font-weight: 400;
}

// On Android 4.4, the old Chromium based "Samsung Internet" browser displays nav items vertically stacked, therefore we need more space
.workaround-nav-samsung-internet-on-android-4 {
  padding-top: 90px;
}


.alert-dark {
  background-color: $elegant-color-dark;
  border-color: $elegant-color-dark;
}

#cookie-preferences-alert {
  box-shadow: 0 0 24px rgba(27, 28, 33, 0.5);
}

@media screen and (max-width: 994px) {
  #modal-dialog-cookies {
    position: fixed !important;
    bottom: 0 !important;
  }
}

#cookie-preferences-alert-cta {
  width: 100%;
}

.enhanced-checkbox.cookie-preferences-checkbox {
  margin-left: 0;
  display: inline-block;
}

#cookie-preferences-accept-all-form, #cookie-preferences-reject-form {
  display: inline;
}

.cookie-preferences-activate-cta {
  display: inline-block;
}

img.img-thumbnail {
  margin-top: 1rem;
}

.badge {
  box-shadow: none;
}

.card-header-inner {
  margin-left: -20px;
  margin-right: -20px;
}

.social-icon {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  margin: 0px 5px;
}

.arrow-btn {
  border-radius: 0px;
  box-shadow: none !important;
  position: relative;
  width: calc(90% - 17px);
  padding-left: 5px;
  padding-right: 5px;

  .right-arrow {
    display:block;
    position: absolute;
    width: 34px;
    height: 34px;
    top: 6px;
    right: -17px;
    border-radius: 3px;
    transform: rotate(45deg);
    font-size:40px;
    padding: 4px;

      .angle {
        width: 100%;
        height: 100%;
        display: block;
        border-right: 1.5px solid #fff;
        border-top: 1.5px solid #fff;
    }
  }
}

.btn-success {
  .right-arrow {
      background: #00c851;
  }
}

.btn-primary {
    .right-arrow {
      background: #CEEBFA;
    }
}

.btn-secondary{
  .right-arrow {
    background: #ff1e00;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.occupational-field-capabilities-star-editor-row {
  cursor: pointer;
}

@media (min-width:768px) and (max-width:1024px) {

  .recurrent-jobs-modal .modal-dialog{
    mim-width:90%;
  }
  .wanted-jobs-modal .modal-dialog{
    mim-width:90%;
  }
}

#anonymous-searchresults-alert {
  border: 3px solid red ;
  border-radius:0;
}

.form-control.valid {
  border: 2px solid #00c851;
}

.form-control.invalid {
  border: 2px solid #d15454;
}

.delete-attachment {
  width: 27px;
  height: 27px;
  position: relative;
  left: -35px;
  border: 0px;
  font-size: 12px;
  background: transparent;
  outline: none !important;
}

.payment-btn {
  min-width: 150px;
  font-size: 14px !important;
  font-weight: bold;
}

#anonymousContactProfileModal .modal-footer {
  max-height: 160px;
  overflow: hidden;
}

#anonymousContactProfileModal .modal-footer img {
  -ms-flex: 1;
}

.td-center {
  max-width: 80%;
  width: 75%;
}

.font-radius {
  color: #C7AF7E !important;
  font-size: 24px;
  font-weight: bold;
}

.font-radius-link {
  text-decoration: underline;
}

.joboo-demo-highlighted-text {
  display: inline-block;
  background-color: lightgreen;
}

.searchterm-match-highlighted-text {
  display: inline-block;
  background-color: yellow;
  border-bottom: 1px black dashed;
}

.profession-chooser-highlighted-text {
  display: inline-block;
  background-color: lightgoldenrodyellow;
}

.profession-chooser-profession-name {
  border-bottom-style: dotted !important;
}

.profession-chooser-proposal-entry-cta-area {
  border-top-style: dotted !important;
}

a.call-to-action, a.call-to-action:active, a.call-to-action:hover, a.call-to-action:visited {
  color: #458eff;
  text-decoration: underline;
  text-decoration-color: #458eff;
}

.lead-text-checked {
  font-size: $font-size-base;
  font-weight: $font-weight-light;
}
.card-header-title {
  font-size: $font-size-base * 1.25;
}

.card-btn {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.card-btn-link {
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}
.card-custom-column {
  max-width: 70%;
}

.card-btn :hover {
  opacity: 0.7;
}

#joboffererModalSlideshow .carousel-control-next,
#joboffererModalSlideshow .carousel-control-prev /*, .carousel-indicators */ {
  filter: invert(70%);
}

#joboffererModalSlideshow .carousel-image {
  height: 25px;
  object-fit: scale-down;
}

#joboffererModalSlideshow .carousel-inner {
  height: 50px;
}

#joboffererModalSlideshow {
  padding-left: 10% !important;
  padding-right: 10% !important;
  padding-top: 10px;
}

#joboffererModalSlideshow .carousel-control-next-icon,
#joboffererModalSlideshow .carousel-control-prev-icon {
  width: 1.5vw !important;
  height: 1.5vw !important;
}

#joboffererModalSlideshow .carousel-control-prev {
  top: -30px;
  left: 4%;
  justify-content: space-between;
  align-items: center;
}

#joboffererModalSlideshow .carousel-control-next {
  top: -30px;
  left: 90%;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 1752px) {
  #infoBoxModal .modal-custom-width {
    width: 35%;
  }
}

@media (max-width: 1752px) and (min-width: 576px)  {
  #infoBoxModal .modal-custom-width {
    width: 700px;
  }
}

@media (max-width: 1752px) {
  #joboffererModalSlideshow .carousel-inner {
    height: 100px;
  }

  #joboffererModalSlideshow .carousel-image {
    height: 35px;
    object-fit: scale-down;
    padding: 7px;
  }
}